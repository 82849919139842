import { Line } from "react-chartjs-2";
import { 
    Chart as ChartJS, 
    Title,
    Tooltip, 
    Legend, 
    CategoryScale, 
    LinearScale,
    LineElement,
    PointElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

const BarChart = (props) => {
    const options = {};
    return (
        <>
            {props.data && <Line options={options} data={props.data} />}
        </>
    )
}

export default BarChart;