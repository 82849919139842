import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Tooltip, Legend, ArcElement);

const PieChart = (props) => {
    const options = {};
    return (
        <>
            {props.data && <Pie options={options} data={props.data} />}
        </>
    )
}

export default PieChart;