export function removeEmpty(obj) {
    Object.keys(obj).forEach(key => {
        if(key === "venue"){
            obj[key] === "" && delete obj['cordinates'];
        }
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });
    return obj;
}