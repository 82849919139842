
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { IoIosSettings } from "react-icons/io";
import { IoIosStats } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import Moment from "moment";
import debounce from "lodash.debounce";
import { IoFilter } from "react-icons/io5";
import DatePicker from "react-datepicker";
import Loader from '../assets/page-loader.gif';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents, setEventId, updateEvent, deleteEvent } from '../redux/event/event.slice';
import { timeConvert } from "../utils/timeConvert";
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    imageBox: {
        width: '50%',
    },
    image: {
        width: '100%',
        borderRadius: 8
    },
    detail: {
        width: '50%',
        padding: '24px 12px'
    },
    name: {
        marginBottom: '0px',
        color: 'rgba(0, 0, 0, 0.7)',
    },
    venue: {
        marginBottom: '0px',
        color: 'rgba(0, 0, 0, 0.5)',
    },
    heading: {
        fontSize: 48,
        marginBottom: 0,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.7)',
    },
    subHeading: {
        marginBottom: '24px',
        textAlign: 'center',
        fontSize: 20,
        color: 'rgba(0, 0, 0, 0.5)'
    },
    searchInput: {
        marginBottom: '24px',
    },
    searchIcon: {
        right: 16,
        top: 4
    },
    totalCount: {
        margin: 0
    },
    btnPub: {
        width: 120,
        marginTop: 8,
        fontWeight: 'bold'
    },
    arertHeading: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold'
    },
    alertDetail: {
        fontSize: 17,
        textAlign: 'center',
    },
    loader: {
        height: 200
    }
});

const EventList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [date, setDate] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [eventStatus, setEventStatus] = useState("");
    const [eventId, setEventId] = useState("");

    const { 
        isLoading, 
        totalCount, 
        eventData  } = useSelector((state) => state.event);

    useEffect(() => {
        dispatch(getEvents({limit, offset, searchText, date}));
    }, [limit, offset, searchText, dispatch, date]);

    const debouncedSave = useMemo(() => 
        debounce((value) => setSearchText(value), 2000), 
        [setSearchText]
    );

    const handleSearchText = useCallback((event) => {
        if(limit === 10 && offset === 0 && date === null){
            debouncedSave(event.target.value);
        }
        else{
            setDate(null);
            setLimit(10);
            setOffset(0);
        }
    }, [limit, offset, date, debouncedSave]);

    // const loadMore = () => {
    //     setLimit(limit + 10);
    //     setOffset(offset);
    // }

    const progressStatus = (text, id) => {
        setEventId(id);
        setEventStatus(text);
    }

    const setDeleteId = (id) => { setEventId(id) }

    const handleRadioChange = (e) => {
        setDate(null);
        setLimit(10);
        setOffset(0);
        e.target.value === "All" ? setSearchText("") : setSearchText(e.target.value)
    }

    const handleDateSelect = (date) => {
        setLimit(10);
        setOffset(0);
        setSearchText("");
        setDate(date.toISOString());
    }

    const previous = (l, o) => {
        setLimit(l-10);
        setOffset(o-10);
    }

    const next = (l, o) => {
        setLimit(l+10);
        setOffset(o+10);
    }
    
    // if(isLoading) return ( <PageLoader />)

    return (
        <div className="container mt-80">
            <h3 className={classes.heading}>Your Events</h3>
            <p className={classes.subHeading}>Overview Of Your Event</p>
            <div className="position-relative">
                <div className={`${classes.searchIcon} position-absolute`}>
                    <FaSearch />
                </div>
                <input 
                    type="text"
                    onChange={handleSearchText}
                    className={`${classes.searchInput} form-control`} 
                    placeholder="Search for an event" 
                />
            </div>
            <div className="main-filter">
                <div className="date-aria">
                    <IoFilter fontSize={24} />
                    <div className="form-box date date-filter">
                        <DatePicker
                            showIcon
                            className="form-control"
                            selected={date ? date : new Date()}
                            onSelect={handleDateSelect} //when day is clicked
                        />
                    </div>
                </div>
                <div>
                    <div className="form-check form-check-inline">
                        <input 
                            className="form-check-input" 
                            type="radio"
                            name="inlineRadioOptions" 
                            id="inlineRadio0"
                            value="All"
                            onChange={handleRadioChange}
                        />
                        <label
                            className="form-check-label fw-bold" 
                            htmlFor="inlineRadio0"
                        >
                            All
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="inlineRadioOptions" 
                            id="inlineRadio1"
                            value="Published"
                            onChange={handleRadioChange}
                        />
                        <label 
                            className="form-check-label fw-bold" 
                            htmlFor="inlineRadio1"
                        >
                            Published
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="inlineRadioOptions" 
                            id="inlineRadio2" 
                            value="Unpublished"
                            onChange={handleRadioChange}
                        />
                        <label 
                            className="form-check-label fw-bold" 
                            htmlFor="inlineRadio2"
                        >
                            Unpublished
                        </label>
                    </div>
                </div>
            </div>
            {isLoading ?
                <div className="d-flex justify-content-center">
                    <img
                        src={Loader}
                        alt="Loader"
                        className={classes.loader}
                    />
                </div>
                :
                <>
                    {eventData && eventData?.length > 0 &&
                        <div className="d-flex justify-content-between mb-3">
                            <p className={classes.totalCount}>
                                Total <b>{totalCount}</b> events found
                            </p>
                            <p className={classes.totalCount}>
                                <b>{offset + 1}</b>{" - "}
                                <b>{totalCount >= limit ? limit : totalCount }</b>{" of "}
                                <b>{totalCount}</b>
                            </p>
                        </div>
                    }
                    {(eventData && eventData?.length === 0) &&
                        <p className={classes.totalCount}>
                            No Event Found
                        </p>
                    }
                    {totalCount > 10 &&
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-between">
                                <li className={`page-item ${offset === 0 && 'disabled'}`}>
                                    <button
                                        className="page-link"
                                        aria-disabled="true"
                                        onClick={() => previous(limit, offset)}>
                                        Previous
                                    </button>
                                </li>
                                <li className={`page-item ${eventData?.length < 10 && 'disabled'}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => next(limit, offset)}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    }
                    {eventData && eventData?.map((item, index) => (
                        <EventCard
                            key={index}
                            item={item}
                            eventId={eventId}
                            eventStatus={eventStatus}
                            setDeleteId={(id) => setDeleteId(id)}
                            progressStatus={(status, id) => progressStatus(status, id)}
                        />
                    ))}
                    {totalCount > 10 &&
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-between">
                                <li className={`page-item ${offset === 0 && 'disabled'}`}>
                                    <button
                                        className="page-link"
                                        aria-disabled="true"
                                        onClick={() => previous(limit, offset)}>
                                        Previous
                                    </button>
                                </li>
                                <li className={`page-item ${eventData?.length < 10 && 'disabled'}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => next(limit, offset)}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    }
                </>
            }
            {/* {(totalCount > 10 && eventData?.length === 10) &&
                <div className="text-center">
                    <button 
                        type="button"
                        onClick={loadMore}
                        className="btn btn-outline-secondary mb-48">
                        Load more
                    </button>
                </div>
            } */}
        </div>
    )
}
const EventCard = ({item, ...props}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const publishUnpublishClicked = () => {
        let body = {
            status: props.eventStatus === 'Published' ? 'Unpublished' : 'Published',
        }
        const res = dispatch(updateEvent({body, id: props.eventId}));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                toast.success(r.payload.message);
                dispatch(getEvents({}));
                return;
            }
            toast.success(r.payload);
        })
    }

    const deleteOneEvent = () => {
        const res = dispatch(deleteEvent(props.eventId));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                toast.success(r.payload.message);
                dispatch(getEvents({}));
                return;
            }
            toast.success(r.payload);
        })
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.detail}>
                    <h3 className={classes.name}>
                        {item?.name} ({item?.city})
                    </h3>
                    <p className={classes.venue}>
                        {item?.venue_name && `${item?.venue_name}, `}
                        {item?.venue ? item?.venue : "Venue to be announced"}{", "}
                        {item?.city}
                    </p>
                    <p className={classes.venue}>
                        {Moment(item?.date).format("MMM DD")}{" "}|{" "}
                        {item?.start_time && timeConvert(item?.start_time)}
                    </p>
                    <div>
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#orderModal"
                            className={`btn ${classes.btnPub} ${item?.status === "Unpublished" ? 'btn-success' : 'btn-warning'}`}
                            onClick={() => props.progressStatus(item?.status, item?._id)}
                        >
                            {item?.status === "Unpublished" ? "Publish ?" : "Unpublish ?" }
                        </button>
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#orderModal1"
                            style={{marginLeft: 8}}
                            onClick={() => props.setDeleteId(item?._id)}
                            className={`btn ${classes.btnPub} ${'btn-danger'}`}
                        >
                            Delete ?
                        </button>
                    </div>
                </div>
                <div className={classes.imageBox}>
                    <img
                        src={item?.image}
                        alt="Card_Image"
                        className={classes.image}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between pd-r">
                <Link 
                    to={`/edit/event/${item?.name}`}
                    onClick={() => dispatch(setEventId(item?._id))}
                >
                    <IoIosSettings />&nbsp;Manage Event
                </Link>
                <Link 
                    to={`/stats/event/${item?.name}`}
                    onClick={() => dispatch(setEventId(item?._id))}
                >
                    <IoIosStats />&nbsp;View Stats
                </Link>
                <div className="d-flex">
                    <Link
                        className="mr-6"
                        to={`/view/event/${item?.name}`}
                        onClick={() => dispatch(setEventId(item?._id))}>
                        <IoEye />&nbsp;View Event
                    </Link>
                    {item?.status === "Unpublished" &&
                        <span className="badge bg-secondary d-flex align-items-center">
                            Unpublished
                        </span>
                    }
                    {item?.status === "Published" &&
                        <span className="badge bg-success d-flex align-items-center">
                            Published
                        </span>
                    }
                </div>
            </div>
            <div
                className="modal fade" 
                id="orderModal"
                data-bs-keyboard="false" 
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 fw-bold">
                                Confirm
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body user-detail">
                            <p className={classes.arertHeading}>Are you sure ?</p>
                            <p className={classes.alertDetail}>
                                You want to {props.eventStatus === "Published" ? "unpublish" : "publish"} the event.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                data-bs-dismiss="modal"
                                className="btn btn-secondary">
                                Cancel
                            </button>
                            <button
                                data-bs-dismiss="modal"
                                onClick={publishUnpublishClicked}
                                className="btn btn-success">
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade" 
                id="orderModal1"
                data-bs-keyboard="false" 
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 fw-bold">
                                Confirm
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body user-detail">
                            <p className={classes.arertHeading}>Are you sure ?</p>
                            <p className={classes.alertDetail}>
                                You want to delete the event.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                data-bs-dismiss="modal"
                                className="btn btn-secondary">
                                Cancel
                            </button>
                            <button
                                data-bs-dismiss="modal"
                                onClick={deleteOneEvent}
                                className="btn btn-success">
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventList;