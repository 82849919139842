import Moment from "moment";
import { useState } from "react";
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { FaRupeeSign } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { getUserById } from "../redux/user/user.slice";
import { useDispatch, useSelector } from 'react-redux';
import { setEventId } from '../redux/event/event.slice';
import { setActiveLink } from '../redux/event/event.slice';

const useStyles = makeStyles({
    root: {
        marginBottom: 0
    },
    totalCount: {
        margin: 0
    },
    pagination: {
        marginBottom: 0
    },
    btn: {
        border: 'none'
    },
    btnColor: {
        color: '#d71ef7'
    },
    noRecord: {
        marginTop: 8
    }
});

const Table = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const [orderData, setOrderData] = useState([]);

    const { userById } = useSelector((state) => state.user)

    const getUserDetail = (id) => dispatch(getUserById(id))

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <p className={classes.totalCount}>
                    Total <b>{props.totalCount}</b> {props.tableFor} found
                </p>
                <div className="d-flex align-items-center">
                    {props.totalCount > 0 &&
                        <p className={classes.totalCount}>
                            <b>{props.offset + 1}</b>{" - "}
                            <b>{props.totalCount >= props.limit ? props.limit : props.totalCount }</b>{" of "}
                            <b>{props.totalCount}</b>
                        </p>
                    }
                    {props.totalCount > 10 &&
                        <div>
                            <ul className={`pagination ${classes.pagination}`}>
                                <li className={`page-item ${props.offset === 0 && 'disabled'}`}>
                                    <button
                                        className={`page-link ${classes.btn}`}
                                        aria-disabled="true"
                                        onClick={() => props.previous(props.limit, props.offset)}>
                                        <FaChevronLeft />
                                    </button>
                                </li>
                                <li className={`page-item ${props.data?.length < 10 && 'disabled'}`}>
                                    <button
                                        className={`page-link ${classes.btn}`}
                                        onClick={() => props.next(props.limit, props.offset)}>
                                        <FaChevronRight />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    }
                    {props.isBtnCreate &&
                        <Link 
                            onClick={() => dispatch(setActiveLink(props.btnText))}
                            data-bs-dismiss="offcanvas"
                            className="btn btn-outline-primary primary-button ml-8"
                            to={props.btnRoute}>
                            {props.btnText}
                        </Link>
                    }
                </div>
            </div>
            <table className={`table ${classes.root}`}>
                <thead>
                    <tr>
                        {props.heading.map((item, index) => (
                            <th scope="col" key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="t-body">
                    {props.tableFor === "users" && 
                        props.data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td className="text-capitalize">{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>{item?.phone}</td>
                            <td>
                                {item?.isVerified ? 
                                <span className="badge bg-success w-84">Verified</span> : 
                                <span className="badge bg-danger w-84">Not Verified</span>}
                            </td>
                        </tr>
                    ))}
                    {props.tableFor === "messages" && 
                        props.data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{Moment(item?.createdBy).format("MMM DD YYYY")}</td>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>{item?.phone}</td>
                            <td>{item?.location}</td>
                            <td style={{width: '450px'}}>{item?.message}</td>
                        </tr>
                    ))}
                    {props.tableFor === "orders" && 
                        props.data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{item?.order_id?.split("_")[1]}</td>
                            <td>{item?.receipt_no}</td>
                            <td>{Moment(item?.updatedBy).format("MMM DD YYYY")}</td>
                            <td>
                                {item?.status === "completed" && 
                                    <span className="badge bg-success w-78">Completed</span>} 
                                {item?.status === "failed" &&
                                    <span className="badge bg-danger w-78">Failed</span>}
                                {item?.status === "in_progress" &&
                                    <span className="badge bg-warning w-78">In Progress</span>}
                            </td>
                            <td>
                                <button 
                                    type="button"
                                    data-bs-toggle="modal"
                                    onClick={() => setOrderData(item)}
                                    data-bs-target="#orderModal"
                                    className="btn btn-primary w-100">
                                    View
                                </button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    onClick={() => getUserDetail(item?.user_id)}
                                    data-bs-target="#orderModal2"
                                    className="btn btn-primary w-100">
                                    View
                                </button>
                            </td>
                            <td>
                                <Link
                                    to={`/view/event/${item?.event_id}`}
                                    onClick={() => dispatch(setEventId(item?.event_id))}
                                    className="btn btn-primary w-100">
                                    View
                                </Link>
                            </td>
                        </tr>
                    ))}
                    {props.tableFor === "images" && 
                        props.data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{item?.title}</td>
                            <td>{item?.category}</td>
                            <td className="g-img">
                                <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#modal"
                                    onClick={() => props.fullImage(item.image)}
                                >
                                    <img src={item.image} alt="" />
                                </button>
                            </td>
                            <td>
                                <button 
                                    data-bs-toggle="modal"
                                    data-bs-target="#modal"
                                    onClick={() => props.deleteImage(item._id)}
                                    className="btn btn-outline-danger"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    {props.tableFor === "sub admin" && 
                        props.data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>
                                {item?.status === "active" ? 
                                    <span className="badge bg-success w-84">Active</span> : 
                                    <span className="badge bg-danger w-84">Suspended</span>
                                }
                            </td>
                            <td style={{width: '380px'}}>
                                <button 
                                    data-bs-toggle="modal"
                                    data-bs-target="#modal"
                                    onClick={() => props.deleteSubAdmin(item._id)}
                                    className="btn btn-outline-danger fw-bold"
                                >
                                    Delete
                                </button>
                                {item?.status === "active" ?
                                    <button 
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal"
                                        onClick={() => props.toggleSuspend(item._id, item?.status)}
                                        className="btn btn-outline-warning ms-3 me-3 fw-bold"
                                    >
                                        Suspend
                                    </button> :
                                    <button 
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal"
                                        onClick={() => props.toggleSuspend(item._id, item?.status)}
                                        className="btn btn-outline-info ms-3 me-3 fw-bold"
                                    >
                                        Activate
                                    </button>
                                }
                                <button 
                                    data-bs-toggle="modal"
                                    data-bs-target="#modal"
                                    onClick={() => props.updatePassword(item._id)}
                                    className="btn btn-outline-success fw-bold"
                                >
                                    Change Password
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {props.totalCount <= 0 && <p className={classes.noRecord}>No Record Found</p>}
            {props.totalCount > 10 &&
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-between">
                        <li className={`page-item ${props.offset === 0 && 'disabled'}`}>
                            <button
                                className="page-link"
                                aria-disabled="true"
                                onClick={() => props.previous(props.limit, props.offset)}>
                                Previous
                            </button>
                        </li>
                        <li className={`page-item ${props.data?.length < 10 && 'disabled'}`}>
                            <button
                                className="page-link"
                                onClick={() => props.next(props.limit, props.offset)}>
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            }
            <div 
                className="modal fade" 
                id="orderModal"
                data-bs-keyboard="false" 
                aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content modal-detail">
                        <div className="modal-body invoice">
                            <div className="d-flex align-items-start justify-content-between">
                                <div className="invoice-detail">
                                    <h5>INVOICE :</h5>
                                    <p className="order-id">
                                        <span>Order ID</span>
                                        <span>: {orderData?.order_id}</span>
                                    </p>
                                    <p className="payment-id">
                                        <span>Payment ID</span>
                                        <span>: {orderData?.payment_id ? orderData?.payment_id : 'N/A'}</span>
                                    </p>
                                    <p className="order-status">
                                        <span>Status</span>:{" "}
                                        {orderData?.status === "completed" && 
                                            <span className="badge bg-success w-78">Completed</span>} 
                                        {orderData?.status === "failed" &&
                                            <span className="badge bg-danger w-78">Failed</span>}
                                        {orderData?.status === "in_progress" &&
                                            <span className="badge bg-warning w-78">In Progress</span>}
                                    </p>
                                </div>
                                <div className="invoice-detail receipt">
                                    {/* <h2>THRIFTYX</h2> */}
                                    <div className="logo-in-invoice">
                                        <img src={logo} alt="Logo" />
                                    </div>
                                    <p>
                                        <span>Receipt No :</span> <span>{orderData?.receipt_no}</span>
                                    </p>
                                    <p>
                                        <span>Issue Date :</span> <span>
                                            {Moment(orderData?.updatedBy).format("MMM Do YY")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="table-responsive order-table">
                                <table className="table table-borderless">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>
                                                Tickets
                                            </th>
                                            <th>
                                                Price
                                            </th>
                                            <th>
                                                Quantity
                                            </th>
                                            <th className="text-end">
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderData?.tickets?.map((item, index) => (
                                            <tr key={index} className="tr-repeat">
                                                <td>{item?.name}</td>
                                                <td>
                                                    <FaRupeeSign size="12" />
                                                    <span>
                                                        {item?.total/item?.count}
                                                    </span>
                                                </td>
                                                <td>{item?.count}</td>
                                                <td className="text-end">
                                                    <FaRupeeSign size="12" />
                                                    <span>{item?.total}.00</span>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="tr-static">
                                            <td></td>
                                            <td></td>
                                            <td className="subtotal">Subtotal</td>
                                            <td className="text-end subtotal">
                                                <FaRupeeSign size="13" />{orderData?.total_price}.00
                                            </td>
                                        </tr>
                                        <tr className="tr-static">
                                            <td></td>
                                            <td></td>
                                            <td className="subtotal">Booking Fee</td>
                                            <td className="text-end subtotal">
                                                <FaRupeeSign size="13" />{orderData?.booking_fee}.00
                                            </td>
                                        </tr>
                                        <tr className="tr-static">
                                            <td></td>
                                            <td></td>
                                            <td className="gst">GST(18%)</td>
                                            <td className="text-end gst">
                                                <FaRupeeSign size="13" />{orderData?.gst}.00
                                            </td>
                                        </tr>
                                        <tr className="tr-total">
                                            <td></td>
                                            <td></td>
                                            <td className="total">Total</td>
                                            <td className="text-end total">
                                                <FaRupeeSign size="13" />
                                                {orderData?.grand_total}.00
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade" 
                id="orderModal2"
                data-bs-keyboard="false" 
                aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">
                                User Detail
                            </h1>
                        </div>
                        <div className="modal-body user-detail">
                            <p>
                                <span>Name : </span>
                                <span>{userById?.name}</span>
                            </p>
                            <p>
                                <span>Email : </span>
                                <span>{userById?.email}</span>
                            </p>
                            <p>
                                <span>Phone : </span>
                                <span>{userById?.phone}</span>
                            </p>
                            <p>
                                <span>Status : </span>
                                {userById?.isVerified ?
                                    <span className="badge bg-success">Verified</span> :
                                    <span className="badge bg-danger">Not Verified</span>}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table;