export function lineChartData(data) {
    const dateMap = {};
  
    data.forEach(order => {
        const updatedDate = new Date(order.updatedBy).toISOString().split('T')[0];

        if (!dateMap[updatedDate]) {
            dateMap[updatedDate] = 0;
        }

        order.tickets.forEach(ticket => {
            dateMap[updatedDate] += Number(ticket.count);
        });
    });
  
    const dates = Object.keys(dateMap);
    const counts = Object.values(dateMap);
  
    return { dates, counts };
}