const routes = Object.freeze({
    home: "/",
    login: "/login",
    gallery: "/gallery",
    dashboard: "/dashboard",
    user_list: "/user-list",
    order_list: "/order-list",
    sub_admin_list: "/sub-admin",
    event_template: '/event/template',
    contact_us_list: "/contact-us-list",
    add_gallery_img: "/add-gallery-image",
    event_view: '/view/event/:event-name',
    event_edit: '/edit/event/:event-name',
    create_new_admin: '/create-new-admin',
    create_new_event: "/create-new-event",
    event_stats: "/stats/event/:event-name",
});
export default routes;