import { useEffect, useState } from "react";
import PageLoader from '../component/PageLoader';
import EventStats from "../component/EventStats";
import { useDispatch, useSelector } from "react-redux";
import { getEventById } from "../redux/event/event.slice";
import { getOrderTickets } from "../redux/user/user.slice";

const StatsView = () => {
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const { isLoading, eventId } = useSelector((state) => state.event);

    useEffect(() => {
        eventId && localStorage.setItem('eventId', eventId);
        let id = eventId ? eventId : localStorage.getItem('eventId');
        dispatch(getEventById({id}));
    },[eventId, dispatch]);

    useEffect(() => {
        let id = eventId ? eventId : localStorage.getItem('eventId');
        dispatch(getOrderTickets({
            limit,
            offset,
            eventId: id
        }));
    },[dispatch, eventId, limit, offset])

    const pageChange = (type) => {
        switch(type){
            case "next" : 
                setLimit(limit+10);
                setOffset(offset+10);
                break;
            case "prev" :
                setLimit(limit-10);
                setOffset(offset-10);
                break;
            default :
                break;

        }
    }
    
    if(isLoading) return ( <PageLoader /> );

    return (
        <EventStats 
            offset={offset}
            pageChange={(text) => pageChange(text)} />
        )
}
export default StatsView;