import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import eventServices from './event.services';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    eventData: null,
    totalCount: 0,
    eventId: null,
    eventById: null,
    galleryData: null,
    activeLink: "Events",
};

export const addEvent = createAsyncThunk('event/addEvent', async(event, thunkAPI) => {
    try{
        return await eventServices.addEvent(event);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateEvent = createAsyncThunk('event/updateEvent', async(event, thunkAPI) => {
    try{
        return await eventServices.updateEvent(event);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getEvents = createAsyncThunk('event/getEvents', async(event, thunkAPI) => {
    try{
        return await eventServices.getEvents(event);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getEventById = createAsyncThunk('event/getEventById', async(event, thunkAPI) => {
    try{
        return await eventServices.getEventById(event);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteEvent = createAsyncThunk('event/deleteEvent', async(params, thunkAPI) => {
    try{
        return await eventServices.deleteEvent(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addGalleryImage = createAsyncThunk('event/addGalleryImage', async(gallery, thunkAPI) => {
    try{
        return await eventServices.addGalleryImage(gallery);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getGallery = createAsyncThunk('event/getGallery', async(gallery, thunkAPI) => {
    try{
        return await eventServices.getGallery(gallery);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteImage = createAsyncThunk('event/deleteImage', async(gallery, thunkAPI) => {
    try{
        return await eventServices.deleteImage(gallery);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventId: (state, action) => {
            state.eventId = action.payload
        },
        setEventByIdData: (state, action) => {
            state.eventById = action.payload
        },
        setActiveLink: (state, action) => {
            state.activeLink = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEvent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEvent.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addEvent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(getEvents.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.eventData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getEventById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getEventById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getEventById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.eventById = action.payload.data;
            })
            .addCase(updateEvent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(getGallery.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getGallery.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getGallery.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.galleryData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(deleteImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteImage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(deleteEvent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
});
export const { 
    setEventId,
    setActiveLink,
    setEventByIdData
} = eventSlice.actions;

export default eventSlice.reducer;